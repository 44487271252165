@import url(https://fonts.googleapis.com/css?family=Montserrat:900&display=swap);
:root {
  --primary-color: #16528a;
  --lighter-primary-color: #1c6ab3;
  --medium-primary-color: #0f3e69;
  --darker-primary-color: #092947;
  --secondary-color: #096386;
  --third-color: #00b7a8;
  --dark-blue-color: #385075;

  /* --primary-color: #e17248;
  --lighter-primary-color: #f37c51;
  --medium-primary-color: #c7633f;
  --darker-primary-color: #81412a;
  --secondary-color: #de425b;
  --third-color: #e1cc73; */


  --uport-color: #5c50ca;
  --metamask-color: #f7861c;
  --success-color: #72c44b; 
  --info-color: #e0e0e0;
  --error-color: #b91c1c;
  --error-bg-color: #ef7c78;
  --warning-color: #bb840c;
  --warning-bg-color: #c5a86b;
  --warning-color: #d47331;

  /* grey scale */
  --dark-grey-color: #333;
  --medium-grey-color: #385075;
  --light-grey-color:   #7b91b1;
  --lighter-grey-color: #E3E6E7;
  --dark-white-color: #f2f2f2;

  --font-color: var(--medium-grey-color);
}

html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}

.ReactModal__Overlay {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.ReactModal__Content {
  max-width: 1024px;
  margin: 0 auto;
}

div[class*=MuiTooltip-tooltip-] {
  font-size: 12px;
}

.fa, .fas {
  color: #ccc !important;
}

